// RecurrenceModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Tabs, DatePicker, InputNumber, Checkbox, Select, Button } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

export default function RecurrenceModal({ isModalVisible, onCloseModal, taskData, handleChange }) {
  const [activeTab, setActiveTab] = useState('daily');
  const [monthlyFrequencyOption, setMonthlyFrequencyOption] = useState('specificDay');
  const [customFrequencyType, setCustomFrequencyType] = useState(null);

  // Al inicio del componente, después de las declaraciones de estado
const getFirstOccurrence = (startDate, recurringType, selected, interval = 1) => {
  const start = dayjs(startDate);

  switch (recurringType) {
    case 'week':
      const selectedDays = selected.sort((a, b) => a - b);
      const startDay = start.day();

      const nextDay = selectedDays.find((day) => day >= startDay);
      if (nextDay !== undefined) {
        return start.add(nextDay - startDay, 'day');
      } else {
        return start.add(7 - startDay + selectedDays[0], 'day');
      }

    case 'month':
      const selectedDay = selected[0];
      if (selectedDay === 'last') {
        return start.endOf('month');
      }

      const currentDay = start.date();
      if (currentDay <= selectedDay) {
        return start.date(selectedDay);
      } else {
        return start.add(1, 'month').date(selectedDay);
      }

    case 'monthday':
      const selectedWeekday = selected[0];
      let date = start.startOf('month');

      while (date.day() !== selectedWeekday) {
        date = date.add(1, 'day');
      }

      if (date.isBefore(start)) {
        date = date.add(1, 'month');
      }

      return date;

    default:
      return start;
  }
};

// Agregar este useEffect después de los otros useEffect existentes
useEffect(() => {
  if (taskData.task_end_date && taskData.task_end_date_option === 'on') {
    // Calculamos la primera ocurrencia con la nueva configuración
    const firstOccurrence = getFirstOccurrence(
      taskData.task_start_date,
      taskData.recurring_type,
      taskData.selected,
      taskData.interval
    );

    // Ajustamos por turno nocturno
    let adjustedFirstOccurrence = dayjs(firstOccurrence);
    if (taskData.task_window_start && taskData.task_window_end) {
      const windowStart = dayjs(taskData.task_window_start, 'HH:mm');
      const windowEnd = dayjs(taskData.task_window_end, 'HH:mm');
      if (windowEnd.isBefore(windowStart)) {
        adjustedFirstOccurrence = adjustedFirstOccurrence.add(1, 'day');
      }
    }

    // Si la fecha final actual es anterior a la primera ocurrencia posible,
    // actualizamos la fecha final
    const currentEndDate = dayjs(taskData.task_end_date);
    if (currentEndDate.isBefore(adjustedFirstOccurrence, 'day')) {
      handleChange('task_end_date', adjustedFirstOccurrence.toISOString());
    }
  }
}, [
  taskData.recurring_type,
  taskData.interval,
  taskData.selected,
  taskData.task_window_start,
  taskData.task_window_end,
  taskData.task_start_date // Agregamos esta dependencia también
]);

  useEffect(() => {
    if (isModalVisible) {
      handleChange('task_end_date_option', '');
      setActiveTab('daily');
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible && activeTab === 'daily') {
      handleChange('recurring_type', 'week');
      handleChange('selected', [0, 1, 2, 3, 4, 5, 6]);
      handleChange('interval', 1);
    }
  }, [isModalVisible, activeTab]);

  async function handleOccurrencesChange(occurrencesValue) {
    handleChange('occurrences', occurrencesValue);

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const response = await fetch('/task_generators/ends_at', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
        body: JSON.stringify({
          task_start_date: taskData.task_start_date,
          recurring_type: taskData.recurring_type,
          selected: taskData.selected,
          interval: taskData.interval,
          task_window_start: taskData.task_window_start,
          task_window_end: taskData.task_window_end,
          occurrences: occurrencesValue,
        }),
      });
      const data = await response.json();
      if (data.date) {
        const isoDate = new Date(data.date).toISOString();
        handleChange('task_end_date', isoDate);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleOk() {
    onCloseModal();
  }

  function handleCancel() {
    // Reset all formik-modified fields when canceling the modal
    handleChange('task_end_date_option', '');
    handleChange('task_end_date', null);
    handleChange('occurrences', '');
    handleChange('selected', []);
    handleChange('recurring_type', '');
    handleChange('interval', '');

    onCloseModal();
  }

  function handleTabChange(tabKey) {
    setActiveTab(tabKey);

    // Reset "Ends" section values
    handleChange('task_end_date_option', '');
    handleChange('task_end_date', null);
    handleChange('occurrences', '');

    // Reset other fields depending on the tab
    handleChange('selected', []);
    handleChange('recurring_type', '');
    setMonthlyFrequencyOption('specificDay');

    if (tabKey === 'daily') {
      handleChange('recurring_type', 'week');
      handleChange('selected', [0, 1, 2, 3, 4, 5, 6]);
      handleChange('interval', 1);
    } else if (tabKey === 'custom') {
      handleChange('recurring_type', 'week');
      handleChange('interval', '');
    } else {
      handleChange('recurring_type', tabKey);
      handleChange('interval', 1);
    }
  }

  function renderDailyOption() {
    return (
      <div className='mb-4'>
        <p className='text-gray-600'>
          This task will repeat every day (technically "week" with all days selected in the
          backend).
        </p>
      </div>
    );
  }

  function renderWeeklyOption() {
    return (
      <div className='mb-4'>
        <label className='block font-medium mb-2'>Days of the Week:</label>
        <div className='flex flex-wrap gap-2'>
          {[
            { index: 0, full: 'Sunday', short: 'S' },
            { index: 1, full: 'Monday', short: 'M' },
            { index: 2, full: 'Tuesday', short: 'T' },
            { index: 3, full: 'Wednesday', short: 'W' },
            { index: 4, full: 'Thursday', short: 'T' },
            { index: 5, full: 'Friday', short: 'F' },
            { index: 6, full: 'Saturday', short: 'S' },
          ].map((dayObj) => {
            const isSelected = (taskData.selected || []).includes(dayObj.index);
            return (
              <div
                key={dayObj.index}
                className={`w-8 h-8 flex items-center justify-center rounded-full text-sm font-bold cursor-pointer transition-all
                  ${
                    isSelected
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-blue-200'
                  }
                `}
                onClick={() => {
                  const newDays = isSelected
                    ? (taskData.selected || []).filter((d) => d !== dayObj.index)
                    : [...(taskData.selected || []), dayObj.index];
                  handleChange('selected', newDays);
                }}
              >
                {dayObj.short}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function renderMonthlyOption() {
    return (
      <div className='mb-4'>
        <div className='mb-4'>
          <label className='block font-medium mb-2'>Choose Monthly Frequency Type:</label>
          <Select
            style={{ width: '220px' }}
            value={monthlyFrequencyOption}
            onChange={(value) => {
              setMonthlyFrequencyOption(value);
              const recurringType = value === 'specificDay' ? 'month' : 'monthday';
              handleChange('recurring_type', recurringType);
              handleChange('selected', []);
              // Reset "Ends" section values when switching between week and month
              handleChange('task_end_date_option', '');
              handleChange('task_end_date', null);
              handleChange('occurrences', '');
            }}
          >
            <Option value='specificDay'>Specific Day of the Month</Option>
            <Option value='ordinalDay'>Ordinal and Day of the Week</Option>
          </Select>
        </div>

        {monthlyFrequencyOption === 'specificDay' && (
          <div className='mb-4'>
            <label className='block font-medium mb-2'>Select specific day of the month:</label>
            <Select
              style={{ width: '220px' }}
              value={taskData.selected[0]}
              onChange={(val) => {
                handleChange('selected', [val]);
              }}
            >
              {Array.from({ length: 28 }, (_, i) => i + 1).map((dayNumber) => (
                <Option key={dayNumber} value={dayNumber}>
                  {dayNumber}
                </Option>
              ))}
              <Option value='last'>Last</Option>
            </Select>
          </div>
        )}

        {monthlyFrequencyOption === 'ordinalDay' && (
          <div className='flex items-center gap-2 mb-4'>
            <label className='block font-medium'>First</label>
            <Select
              style={{ width: '220px' }}
              value={taskData.selected[0]}
              onChange={(val) => {
                handleChange('selected', [val]);
              }}
            >
              <Option value={0}>Sunday</Option>
              <Option value={1}>Monday</Option>
              <Option value={2}>Tuesday</Option>
              <Option value={3}>Wednesday</Option>
              <Option value={4}>Thursday</Option>
              <Option value={5}>Friday</Option>
              <Option value={6}>Saturday</Option>
            </Select>
            <label className='block font-medium'>of the month</label>
          </div>
        )}
      </div>
    );
  }

  function renderCustomOption() {
    return (
      <div className='mb-4'>
        <label className='block font-medium mb-2'>Every:</label>
        <div className='flex items-center gap-2 mb-4'>
          <InputNumber
            min={1}
            value={taskData.interval}
            onChange={(value) => handleChange('interval', value)}
          />
          <Select
            style={{ width: '200px' }}
            value={customFrequencyType || undefined}
            onChange={(val) => {
              setCustomFrequencyType(val);
              handleChange('recurring_type', val);
              handleChange('selected', []);
              // Reset "Ends" section values when switching between week and month
              handleChange('task_end_date_option', '');
              handleChange('task_end_date', null);
              handleChange('occurrences', '');
            }}
            placeholder='Select frequency type'
          >
            <Option value='week'>Week</Option>
            <Option value='month'>Month</Option>
          </Select>
        </div>
        {customFrequencyType === 'week' && renderWeeklyOption()}
        {customFrequencyType === 'month' && renderMonthlyOption()}
      </div>
    );
  }

  function renderEndsSection() {
    const isCustomTabComplete =
      taskData.recurring_type &&
      taskData.interval &&
      taskData.selected &&
      taskData.selected.length > 0;

    if (activeTab === 'custom' && !isCustomTabComplete) {
      return null;
    }

    return (
      <div className='mt-6'>
        <div className='mb-2 font-medium'>Ends</div>
        <div className='flex flex-col gap-2'>
          <div className='flex items-center'>
            <Checkbox
              checked={taskData.task_end_date_option === 'never'}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChange('task_end_date_option', 'never');
                  handleChange(
                    'task_end_date',
                    new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString()
                  );
                  handleChange('occurrences', '');
                }
              }}
            >
              Never
            </Checkbox>
          </div>
          <div className='flex items-center'>
            <Checkbox
              checked={taskData.task_end_date_option === 'on'}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChange('task_end_date_option', 'on');
                  handleChange('task_end_date', null);
                  handleChange('occurrences', '');
                }
              }}
            >
              On
            </Checkbox>
            {taskData.task_end_date_option === 'on' && (
              <DatePicker
                format='DD-MM-YYYY'
                className='ml-2'
                value={taskData.task_end_date ? dayjs(taskData.task_end_date) : null}
                disabledDate={(current) => {
                  if (!taskData.task_start_date) return false;

                  const firstOccurrence = getFirstOccurrence(
                    taskData.task_start_date,
                    taskData.recurring_type,
                    taskData.selected,
                    taskData.interval
                  );

                  let adjustedFirstOccurrence = dayjs(firstOccurrence);
                  if (taskData.task_window_start && taskData.task_window_end) {
                    const windowStart = dayjs(taskData.task_window_start, 'HH:mm');
                    const windowEnd = dayjs(taskData.task_window_end, 'HH:mm');
                    if (windowEnd.isBefore(windowStart)) {
                      adjustedFirstOccurrence = adjustedFirstOccurrence.add(1, 'day');
                    }
                  }

                  return current && current.isBefore(adjustedFirstOccurrence, 'day');
                }}
                onChange={(date) => {
                  if (date) {
                    const formattedDate = date.toISOString();
                    handleChange('task_end_date', formattedDate);
                  } else {
                    handleChange('task_end_date', null);
                  }
                }}
              />
            )}
          </div>
          <div className='flex items-center'>
            <Checkbox
              checked={taskData.task_end_date_option === 'after'}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChange('task_end_date_option', 'after');
                  handleChange('task_end_date', null);
                }
              }}
            >
              After
            </Checkbox>
            {taskData.task_end_date_option === 'after' && (
              <div className='ml-2 flex items-center gap-2'>
                <InputNumber
                  type='number'
                  min={1}
                  className='w-20'
                  value={taskData.occurrences}
                  onChange={(val) => {
                    handleChange('occurrences', val);
                    handleOccurrencesChange(val);
                  }}
                />
                <span>occurrences</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const tabItems = [
    {
      key: 'daily',
      label: 'Daily/One-Time',
      children: renderDailyOption(),
    },
    {
      key: 'custom',
      label: 'Custom',
      children: renderCustomOption(),
    },
  ];

  return (
    <Modal
      title='Frequency Settings'
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='ok' type='primary' onClick={handleOk}>
          OK
        </Button>,
      ]}
      className='max-w-lg min-h-'
    >
      <Tabs activeKey={activeTab} onChange={handleTabChange} items={tabItems} className='mb-4' />
      {renderEndsSection()}
    </Modal>
  );
}

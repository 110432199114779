// NewTask.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Input, Select, TimePicker, Button, Checkbox, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { initializeTaskData, updateHiddenFields } from './utils/taskSchedulerUtils';
import {
  fetchIntersectDates,
  customDayContent,
  validationSchema,
  handleFieldChange,
} from './utils/taskFormUtils';
import { useFormik } from 'formik';
import RecurrenceModal from './RecurrenceModal';
// Import the date range picker
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Initialize plugins in the correct order
dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

function generateRecurrenceSummary(data) {
  if (!data.recurring_type) {
    return 'No frequency set.';
  }

  const interval = data.interval || 1;
  const selectedDays = data.selected || [];

  // Special check: If recurring_type is 'week', interval = 1, and selectedDays covers [0..6],
  // then we call it "Repeats daily" (like Google Calendar's daily).
  const isDailyEquivalent =
    data.recurring_type === 'week' &&
    interval === 1 &&
    selectedDays.length === 7 &&
    new Set(selectedDays).size === 7; // ensures it's exactly [0..6]

  let endDateDescription;
  if (!data.task_end_date && data.task_end_date_option === 'never') {
    endDateDescription = 'no end date';
  } else if (data.task_end_date_option === 'on' && data.task_end_date) {
    endDateDescription = `last occurrence ${dayjs(data.task_end_date).format('MMMM D, YYYY')}`;
  } else if (data.task_end_date_option === 'after' && data.occurrences) {
    endDateDescription = `${data.occurrences} occurrences (last occurrence ${dayjs(data.task_end_date).format(
      'MMMM D, YYYY'
    )})`;
  } else if (data.task_end_date) {
    endDateDescription = `last occurrence ${dayjs(data.task_end_date).format('MMMM D, YYYY')}`;
  } else {
    endDateDescription = 'no end date';
  }

  if (isDailyEquivalent) {
    return `Repeats daily, ${endDateDescription}.`;
  }

  const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const formattedDays = selectedDays.map((dayIndex) => daysMap[dayIndex]).join(', ');

  switch (data.recurring_type) {
    case 'week':
      return `Repeats every ${
        interval > 1 ? `${interval} weeks` : 'week'
      } on ${formattedDays}, ${endDateDescription}.`;
    case 'month':
      if (data.selected && data.selected[0] === 'last') {
        return `Repeats every ${
          interval > 1 ? `${interval} months` : 'month'
        } on the last day of the month, ${endDateDescription}.`;
      }
      return `Repeats every ${interval > 1 ? `${interval} months` : 'month'} on day ${
        data.selected[0] || 1
      }, ${endDateDescription}.`;
    case 'monthday':
      return `Repeats every ${interval > 1 ? `${interval} months` : 'month'} on the ${
        daysMap[data.selected[0]]
      }, ${endDateDescription}.`;
    case 'custom':
      return `Repeats every ${interval} ${
        interval === 1 ? data.recurring_type : data.recurring_type + 's'
      }, last occurence ${endDateDescription}.`;
    default:
      return `Repeats every day, ${endDateDescription}.`;
  }
}


const NewTask = ({ taskGenerator, shifts, users, inmates, taskTypes, sharedTasks }) => {
  const [taskData, setTaskData] = useState(initializeTaskData());
  const [assignToInmate, setAssignToInmate] = useState(false);
  const [isRecurrenceModalVisible, setIsRecurrenceModalVisible] = useState(false);
  const [intersectDates, doSetIntersectDates] = useState([]);
  const [frequencySummary, setFrequencySummary] = useState('No frequency set.');

  const parseBackendDate = (dateString) => {
    const timeMatch = dateString.match(/T(\d{2}:\d{2})/);
    return timeMatch ? timeMatch[1] : '';
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const formik = useFormik({
    initialValues: {
      name: taskData.name || '',
      description: taskData.description || '',
      task_window_start: '',
      task_window_end: '',
      task_start_date: taskData.task_start_date,
      recurring_type: '',
      interval: '',
      selected: [],
      task_end_date: null,
      task_end_date_option: 'never',
      occurrences: '',
      officer_ids: [],
      inmate_ids: [],
      shared_task: false,
      task_type: 1,
      auto_archive_threshold: 3,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => {
      console.log('Validated Values:', values);
      values;
    },
  });
  const isFormComplete = () => {
    const requiredFields = [
      'name',
      'description',
      'task_start_date',
      'task_window_start',
      'task_window_end',
      'recurring_type',
      'interval',
    ];
    return requiredFields.every((field) => !!formik.values[field]);
  };

  useEffect(() => {
    setTaskData((prev) => ({
      ...prev,
      name: taskGenerator.name || '',
      description: taskGenerator.description || '',
    }));
    Object.entries(taskData).forEach(([key, value]) => {
      updateHiddenFields(key, value);
    });
  }, [taskGenerator]);

  const handleChange = (key, value) => {
    setTaskData((prev) => ({ ...prev, [key]: value }));
    updateHiddenFields(key, value);
  };

  useEffect(() => {
    formik.setFieldTouched('officer_ids', true, false);
  }, []);

  useEffect(() => {
    document.querySelector('.rdrMonths.rdrMonthsHorizontal').style.pointerEvents = 'none';
  }, []);

  useEffect(() => {
    const start = formik.values.task_start_date
      ? new Date(formik.values.task_start_date).toISOString().split('T')[0]
      : null;
    const end = formik.values.task_end_date
      ? new Date(formik.values.task_end_date).toISOString().split('T')[0]
      : null;

    const parsedStart = start ? new Date(`${start}T00:00:00`) : new Date();
    const parsedEnd = end ? new Date(`${end}T00:00:00`) : parsedStart;

    setIntersectDates({ ...formik.values, start: parsedStart, end: parsedEnd });

    setDateRange([
      {
        startDate: parsedStart,
        endDate: parsedEnd,
        key: 'selection',
      },
    ]);
  }, [formik.values.task_start_date, formik.values.task_end_date]);

  const setIntersectDates = useCallback(
    (values) => {
      fetchIntersectDates(values, doSetIntersectDates); // Use utility function
    },
    [doSetIntersectDates]
  );
  useEffect(() => {
    const newSummary = generateRecurrenceSummary(formik.values);
    setFrequencySummary(newSummary);
  }, [
    formik.values.task_start_date,
    formik.values.recurring_type,
    formik.values.interval,
    formik.values.selected,
    formik.values.task_end_date,
    formik.values.task_end_date_option,
    formik.values.occurrences,
  ]);

  useEffect(() => {
    formik.setFieldTouched('officer_ids', true, false);
  }, []);

  return (
    <div className='max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-lg space-y-8'>
      {/* Title Section */}
      <div className='border-b pb-4'>
        <Input
          name='name'
          placeholder='Task Title'
          value={formik.values.name}
          onChange={(e) => handleFieldChange(formik, 'name', e.target.value, handleChange)}
          onBlur={formik.handleBlur}
          className='text-2xl font-semibold border-0 px-0 mb-1 focus:ring-0 focus:border-b-2 focus:border-blue-500 w-full transition-all'
        />
        {formik.touched.name && formik.errors.name && (
          <div className='text-red-500 text-sm mt-1'>{formik.errors.name}</div>
        )}
      </div>

      {/* Description Section */}
      <div className='bg-gray-50 p-4 rounded-lg'>
        <label htmlFor='description' className='block mb-2 font-medium text-gray-700'>
          Task Description
        </label>
        <Input.TextArea
          name='description'
          value={formik.values.description}
          onChange={(e) => handleFieldChange(formik, 'description', e.target.value, handleChange)}
          onBlur={formik.handleBlur}
          className='w-full border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all'
          rows={4}
        />
        {formik.touched.description && formik.errors.description && (
          <div className='text-red-500 text-sm mt-1'>{formik.errors.description}</div>
        )}
      </div>

      {/* Task Type Section */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <div>
          <label htmlFor='task_type' className='block mb-2 font-medium text-gray-700'>
            Task Type
          </label>
          <Select
            name='task_type'
            value={formik.values.task_type}
            onBlur={formik.handleBlur}
            onChange={(value) =>
              handleFieldChange(formik, 'task_type', Number(value), handleChange)
            }
            className='w-full'
            style={{ height: '40px' }}
          >
            {taskTypes.map((type) => (
              <Option key={type.value} value={type.value}>
                {type.label}
              </Option>
            ))}
          </Select>
          {formik.touched.task_type && formik.errors.task_type && (
            <div className='text-red-500 text-sm mt-1'>{formik.errors.task_type}</div>
          )}
        </div>

        {/* Conditional Shift/Officer Selection */}
        {formik.values.task_type === 0 ? (
          <div>
            <label htmlFor='shift_id' className='block mb-2 font-medium text-gray-700'>
              Select Shift
            </label>
            <Select
              name='shift_id'
              value={formik.values.shift_id}
              onBlur={formik.handleBlur}
              onChange={async (value) => {
                handleFieldChange(formik, 'shift_id', value, handleChange);
                const token = document.querySelector('meta[name="csrf-token"]').content;

                try {
                  // Primera solicitud: Información del Shift
                  const shiftResponse = await fetch(`/shifts/${value}`, {
                    method: 'GET',
                    headers: {
                      'X-CSRF-Token': token,
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                    },
                  });

                  if (!shiftResponse.ok) {
                    throw new Error('Failed to fetch shift data');
                  }

                  const shiftData = await shiftResponse.json();
                  console.log('Shift Data:', shiftData);

                  // Segunda solicitud: Fechas del Shift
                  const datesResponse = await fetch(`/get_shift_dates/${value}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });

                  if (!datesResponse.ok) {
                    throw new Error('Failed to fetch shift dates');
                  }

                  const datesData = await datesResponse.json();

                  if (datesData.starts_at && datesData.ends_at) {
                    const startTime = parseBackendDate(datesData.starts_at);
                    const endTime = parseBackendDate(datesData.ends_at);

                    formik.setFieldValue('task_window_start', startTime);
                    formik.setFieldValue('task_window_end', endTime);

                    if (handleChange) {
                      handleChange('task_window_start', startTime);
                      handleChange('task_window_end', endTime);
                    }
                  }
                } catch (error) {
                  console.error('Error fetching shift data:', error);
                }
              }}
              className='w-full'
              style={{ height: '40px' }}
            >
              {shifts.map((shift) => (
                <Option key={shift.id} value={shift.id}>
                  {shift.name}
                </Option>
              ))}
            </Select>

            {formik.touched.shift_id && formik.errors.shift_id && (
              <div className='text-red-600 text-sm mt-1'>{formik.errors.shift_id}</div>
            )}
          </div>
        ) : (
          <div className='space-y-4'>
            <div>
              <label htmlFor='officer_ids' className='block mb-2 font-medium text-gray-700'>
                Select Officer(s)
              </label>
              <Select
                mode='multiple'
                name='officer_ids'
                onBlur={formik.handleBlur}
                value={formik.values.officer_ids}
                onChange={(value) => handleFieldChange(formik, 'officer_ids', value, handleChange)}
                className='w-full'
                style={{ minHeight: '40px' }}
              >
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.full_name}
                  </Select.Option>
                ))}
              </Select>
              {formik.touched.officer_ids && formik.errors.officer_ids && (
                <div className='text-red-600 text-sm mt-1'>{formik.errors.officer_ids}</div>
              )}
            </div>
            <div>
              <label htmlFor='sharedTask' className='block mb-2 font-medium text-gray-700'>
                Responsibility
              </label>
              <Select
                value={formik.values.shared_task}
                onChange={(value) => handleFieldChange(formik, 'shared_task', value, handleChange)}
                className='w-full'
                name='shared_task'
                onBlur={formik.handleBlur}
                style={{ height: '40px' }}
              >
                {sharedTasks.map(([label, val]) => (
                  <Select.Option key={val} value={val}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        )}
      </div>

      {/* Inmate Assignment Section */}
      <div className='bg-gray-50 p-4 rounded-lg'>
        <Checkbox
          checked={assignToInmate}
          onChange={(e) => setAssignToInmate(e.target.checked)}
          className='text-gray-700 font-medium'
        >
          Assign to Specific Inmate(s)?
        </Checkbox>

        {assignToInmate && (
          <div className='mt-4'>
            <Select
              mode='multiple'
              name='inmate_ids'
              onBlur={formik.handleBlur}
              value={formik.values.inmate_ids}
              onChange={(value) => handleFieldChange(formik, 'inmate_ids', value, handleChange)}
              className='w-full'
              style={{ minHeight: '40px' }}
            >
              {inmates.map((inmate) => (
                <Select.Option key={inmate.id} value={inmate.id}>
                  {inmate.full_name} - {inmate.id}
                </Select.Option>
              ))}
            </Select>
            {formik.touched.inmate_ids && formik.errors.inmate_ids && (
              <div className='text-red-600 text-sm mt-1'>{formik.errors.inmate_ids}</div>
            )}
          </div>
        )}
      </div>

      {/* Time and Date Section */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <div>
          <label htmlFor='task_start_date' className='block mb-2 font-medium text-gray-700'>
            Task Start Date
          </label>
          <DatePicker
            format='MM-DD-YYYY'
            name='task_start_date'
            onBlur={formik.handleBlur}
            value={formik.values.task_start_date ? dayjs(formik.values.task_start_date) : null}
            onChange={(date) => {
              const formattedDate = date ? date.toISOString() : null;
              formik.setFieldValue('task_start_date', formattedDate);

              formik.setFieldValue('recurring_type', '');
              formik.setFieldValue('interval', '');
              formik.setFieldValue('selected', []);
              formik.setFieldValue('task_end_date', null);
              formik.setFieldValue('task_end_date_option', 'never');
              formik.setFieldValue('occurrences', '');

              if (handleChange) {
                handleChange('task_start_date', formattedDate);
              }
            }}
            disabledDate={(currentDate) => {
              return currentDate && currentDate.isBefore(dayjs().startOf('day'));
            }}
            className='w-full h-10'
          />
        </div>

        <div>
          <label htmlFor='task_time_window' className='block mb-2 font-medium text-gray-700'>
            Task Time Window
          </label>
          <TimePicker.RangePicker
            order={false}
            needConfirm={false}
            className='w-full h-10'
            format='HH:mm'
            placeholder={['Start time', 'End time']}
            size='middle'
            inputReadOnly={true}
            use12Hours={false}
            value={
              formik.values.task_window_start && formik.values.task_window_end
                ? [
                    dayjs(formik.values.task_window_start, 'HH:mm'),
                    dayjs(formik.values.task_window_end, 'HH:mm'),
                  ]
                : null
            }
            onChange={(time) => {
              if (time && time.length === 2) {
                const startTime = time[0];
                const endTime = time[1];

                if (startTime && endTime) {
                  const formattedStartTime = startTime.format('HH:mm');
                  const formattedEndTime = endTime.format('HH:mm');

                  formik.setFieldValue('task_window_start', formattedStartTime);
                  formik.setFieldValue('task_window_end', formattedEndTime);

                  formik.setFieldError('task_window_start', '');
                  formik.setFieldError('task_window_end', '');

                  formik.setFieldValue('recurring_type', '');
                  formik.setFieldValue('interval', '');
                  formik.setFieldValue('selected', []);
                  formik.setFieldValue('task_end_date', null);
                  formik.setFieldValue('occurrences', '');

                  if (handleChange) {
                    handleChange('task_window_start', formattedStartTime);
                    handleChange('task_window_end', formattedEndTime);
                  }
                }
              } else {
                // Limpia los valores si no es un rango válido
                formik.setFieldValue('task_window_start', '');
                formik.setFieldValue('task_window_end', '');
                formik.setFieldError('task_window_end', 'Please select a valid time range.');
              }
            }}
          />

          {formik.touched.task_window_start && formik.errors.task_window_start && (
            <div className='text-red-600 text-sm mt-1'>{formik.errors.task_window_start}</div>
          )}
          {formik.touched.task_window_end && formik.errors.task_window_end && (
            <div className='text-red-600 text-sm mt-1'>{formik.errors.task_window_end}</div>
          )}
        </div>
      </div>
      <div className="w-full flex item-center justify-center">
        <button
          onClick={() => setIsRecurrenceModalVisible(true)}
          type='button'
          className={`bg-jailcoreBlue hover:bg-jailcoreBlue/50 text-white px-4 w-full max-w-md py-2 ${
            !formik.values.task_window_start || !formik.values.task_window_end
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
          disabled={!formik.values.task_window_start || !formik.values.task_window_end}
        >
          SET FREQUENCY
        </button>
      </div>

      {/* Frequency Summary */}
      <div className='space-y-4'>
        <div className='flex items-center justify-start'>
          <div className='text-gray-700 font-medium'>Frequency Summary</div>

        </div>
        <div
          className={`p-4 rounded-lg ${
            frequencySummary === 'No frequency set.'
              ? 'bg-red-100 text-red-700 font-semibold'
              : 'bg-gray-50 text-gray-800'
          }`}
        >
          {frequencySummary}
        </div>
      </div>

      {/* Date Range Preview */}
      <div className='bg-white rounded-lg shadow-sm border p-4'>
        <label className='block mb-4 font-medium text-gray-700'>Date Range Preview</label>
        <div className='w-full flex items-center justify-center'>
          <DateRange
            ranges={dateRange}
            onChange={() => {}}
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            showSelectionPreview={true}
            months={2}
            direction='horizontal'
            rangeColors={['#000052']}
            disabledDates={[]}
            minDate={new Date()}
            dayContentRenderer={(day) => customDayContent(day, intersectDates, dateRange)}
            dateDisplayFormat='MM/dd/yyyy'
            className='border-0'
          />
        </div>
      </div>
      <RecurrenceModal
        isModalVisible={isRecurrenceModalVisible}
        onCloseModal={() => setIsRecurrenceModalVisible(false)}
        taskData={formik.values}
        handleChange={(key, value) => {
          formik.setFieldValue(key, value);
          handleChange(key, value);
        }}
      />
      <div className='w-full flex justify-end'>
        <button
          type='submit'
          className={`p-4 text-white ${
            isFormComplete()
              ? 'bg-jailcoreBlue hover:bg-jailcoreBlue/50'
              : 'bg-gray-400 cursor-not-allowed'
          }`}
          disabled={!isFormComplete()}
        >
          Create Task
        </button>
      </div>
    </div>
  );
};

export default NewTask;
